import React, { useEffect, useState } from 'react'
import "./style.css"
import { RiAddCircleLine, RiCloseLine, RiEdit2Line, RiFilter2Line } from "react-icons/ri";
import { useMutation, useQuery } from '@apollo/client';
import { GET_ADMINS } from '../graphql/query';
import { CREATE_ADMIN, DELETE_ADMIN, UPDATE_ADMIN } from '../graphql/mutation';

export default function Admin({user}) {
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const {data,loading} = useQuery(GET_ADMINS)
  const [selectId, setSelect] = useState()

  const select = data?.getAdmins?.filter((e)=>e?.id === selectId)[0]

  const [search, setSearch] = useState([])

  const handleSearch = (query) => {
    const filteredResults = data?.getAdmins?.filter(item =>
      item?.name?.toLowerCase().includes(query.toLowerCase())
    );

    setSearch(filteredResults);
  };

  if(loading){
    return (
      <div className='Loading'>
        Loading...
      </div>
    )
  }
  return (
    <>
    <div className='Home'>
      <div className='HomeHead'>
        ADMINS
        <div className='FlexCon' />
      </div>
      <div className='UserDiv'>
        <div className='UserDivLeft'>
          <div className='UserSearchCon'>
            <input onChange={(e)=>handleSearch(e?.target?.value)} placeholder='Search user, email' />
            <div className='FlexCon' />
          </div>
          <div className='UserCon'>
            <div className='UserHead' onClick={()=>console.log(data?.getUsers)}>
              All Admins
              <h4>{data?.getAdmins?.length}</h4>
              <div className='FlexCon' />
              {user?.role === 'super admin'?
              <button onClick={()=>setOpen(true)}><RiAddCircleLine/> Create Admin</button>
              : null}
            </div>
            <div className='UserHead1'>
              <h2>Name</h2>
              <h2>Email</h2>
              <h2>Roles</h2>
              {/* <h2>Status</h2> */}
            </div>
            {search?.length>0?
            search?.map((item, index)=>{
              return <Users key={index} item={item} setSelect={setSelect}/>
            })
            :
            data?.getAdmins?.slice()?.sort((a,b)=>{
              if ( a.email < b.email ){
                return -1;
              }
              if ( a.email > b.email ){
                return 1;
              }
              return 0;
            })?.map((item, index)=>{
              return(
                <Users key={index} item={item} setSelect={setSelect}/>
              )
            })}
          </div>
        </div>
        <div className='UserDivRight'>
          {select?
          <>
          <div className='UserDivRightProfile'>
            <img src='https://ittacademy.net.au/wp-content/uploads/2022/12/bio-placeholder-img.jpg'/>
            <div>
              <h2>{select?.name}</h2>
              <h3>Role: {select?.role}</h3>
            </div>
            <div className='FlexCon'/>
            {user?.role === 'super admin'?
            <div className='UserDivRightEdit' onClick={()=>setEdit(true)}>
              <RiEdit2Line/>
            </div>
            : null}
          </div>
          <div className='UserDivRightDiv'>
            <h4>Email:</h4>
            <h5>{select?.email}</h5>
          </div>
          </>
          : null}
        </div>
      </div>
    </div>
    <div className={edit?'UserModal UserModalOpen':'UserModal'}>
      <EditUser setOpen={setEdit} open={select}/>
    </div>
    <div className={open?'UserModal UserModalOpen':'UserModal'}>
      <CreateUser setOpen={setOpen} open={open}/>
    </div>
    </>
  )
}

function CreateUser({setOpen, open}){
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [mail, setMail] = useState('Yes')
  const [role, setRole] = useState("")

  const [onCreate, {loading}] = useMutation(CREATE_ADMIN,{
    refetchQueries:[
      {query:GET_ADMINS}
    ]
  })

  return(
    <div className='UserModalCon'>
    <div className='UserModalHead'>
      Add Admin
    </div>
    <RiCloseLine className='UserModalClose' size={18} onClick={()=>setOpen(false)}/>
      <div className='UserModalCon1'>
        <div>
          <h2>User Admin</h2>
          <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter Admin Name'/>
        </div>
        <div className='UserModalInput'>
          <h2>Email Address</h2>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Email Address'/>
        </div>
      </div>
      <div className='UserModalCon1'>
      <div>
          <h2>Role</h2>
          <select value={role} onChange={(e)=>setRole(e.target.value)}>
            <option hidden>Select Role</option>
            <option value={'super admin'}>Super Admin</option>
            <option value={'view admin'}>View only Admin</option>
          </select>
        </div>
        <div className='UserModalInput'>
        <h2>Password</h2>
          <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Create Password'/>
        </div>
      </div>
      <div className='UserModalCon4'>
        <div className='FlexCon'/>
        <input checked={mail === 'Yes'} onChange={()=>mail === 'Yes'? setMail('No'):setMail('Yes')} type='checkbox'/>
        <p onClick={()=>mail === 'Yes'? setMail('No'):setMail('Yes')}>Send Email</p>
        <button onClick={()=>{
          onCreate({
            variables:{
              "user": {
                "email": email,
                "name": name,
                "password": password,
                "mail":mail,
                "role": role
              }
            }
          }).then(()=>{
            setOpen(false)
            setEmail("")
            setMail("")
            setPassword("")
            setName("")
            setRole("")
          }).catch((err)=>{
            alert(err?.message)
          })
        }}>{loading? "Loading" : "Create Admin"}</button>
      </div>
    </div>
  )
}

function EditUser({setOpen, open}){
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [mail, setMail] = useState('No')
  const [del, setDel] = useState(false)
  const [status, setStatus] = useState("")
  
  useEffect(()=>{
    setEmail(open?.email)
    setName(open?.name)
    setPassword("")
    setMail("No")
    setStatus(open?.role || '')
  },[open])

  const [onCreate, {loading}] = useMutation(UPDATE_ADMIN,{
    refetchQueries:[
      {query:GET_ADMINS}
    ]
  })

  const [load, setLoad] = useState(false)

  const [onDelete] = useMutation(DELETE_ADMIN,{
    refetchQueries:[
      {query:GET_ADMINS}
    ]
  })

  return(
    <div className='UserModalCon'>
    <div className='UserModalHead' onClick={()=>console.log(open)}>
      Edit Admin
    </div>
    <RiCloseLine className='UserModalClose' size={18} onClick={()=>{
      setOpen(false)
      setDel(false)
    }}/>
      <div className='UserModalCon1'>
        <div>
          <h2>User Admin</h2>
          <input value={name} onChange={(e)=>setName(e.target.value)} placeholder='Enter Admin Name'/>
        </div>
        <div className='UserModalInput'>
          <h2>Email Address</h2>
          <input value={email} onChange={(e)=>setEmail(e.target.value)} placeholder='Enter Email Address'/>
        </div>
      </div>
      <div className='UserModalCon1'>
        <div>
          <h2>Password</h2>
          <input value={password} onChange={(e)=>setPassword(e.target.value)} placeholder='Create New Password'/>
        </div>
        <div className='UserModalInput'>
          <h2>Role</h2>
          <select value={status} onChange={(e)=>setStatus(e.target.value)}>
            <option hidden>Select Role</option>
            <option value={'super admin'}>Super Admin</option>
            <option value={'view admin'}>View only Admin</option>
          </select>
        </div>
      </div>
      <div className='UserModalCon4'>
        <div className='FlexCon'/>
        {password || email !== open?.email?
        <>
        <input checked={mail === 'Yes'} onChange={()=>mail === 'Yes'? setMail('No'):setMail('Yes')} type='checkbox'/>
        <p onClick={()=>mail === 'Yes'? setMail('No'):setMail('Yes')}>Send Email</p>
        </>
        :
        <button style={{backgroundColor:"red"}} onClick={()=>{
          if(del){
            setLoad(true)
            onDelete({
              variables:{
                "deleteUserId": open?.id
              }
            }).then(()=>{
              setLoad(false)
              setDel(false)
              setOpen(false)
            }).catch((err)=>{
              setLoad(false)
              alert(err?.message)
            })
          }else if(open?.nations?.length > 0 || open?.regions?.length > 0 || open?.areas?.length > 0){
            alert("Please remove all roles before deleting")
          }else{
            setDel(!del)
          }
        }}>{load? "Deleting" : del? "Confirm?" : "Delete"}</button>
        }
        <button onClick={()=>{
          if(email !== open?.email){
            if(name && password){
              onCreate({
                variables:{
                  "user": {
                    "id": open?.id,
                    "email": email,
                    "name": name,
                    "password": password,
                    "mail":mail
                  }
                }
              }).then(()=>{
                setOpen(false)
                setDel(false)
              }).catch((err)=>{
                alert(err?.message)
              })
            }else{
              alert(name? "Create new password":"Enter name and password")
            }
          }else if(email && name){
            onCreate({
              variables:{
                "user": {
                  "id": open?.id,
                  "email": email,
                  "name": name,
                  "password": password,
                  "mail":mail,
                  "role":open?.role
                }
              }
            }).then(()=>{
              setOpen(false)
              setDel(false)
            }).catch((err)=>{
              alert(err?.message)
            })
          }else{
            alert("Enter name and email")
          }
        }}>{loading? "Loading" : "Update Admin"}</button>
      </div>
    </div>
  )
}

function Users({item, setSelect}){
  return(
    <div className='UserCon1' onClick={()=>setSelect(item?.id)}>
      <div className='UserConImg'>
        <h2>{item?.name}</h2>
      </div>
      <h3>{item?.email || "--"}</h3>
      <h3>{item?.role}</h3>
      {/* <h4 style={{color: item?.status === 'Inactive'?'red':null, backgroundColor:item?.status === 'Inactive'?"#f7e0e0":null}}>{item?.status? item?.status : "Active"}</h4> */}
    </div>
  )
}